import React from 'react'
import { PageProps } from 'gatsby'
import clsx from 'clsx'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import { Testimonials } from '../../../components/SolutionsPages'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  faUser,
  faBookmark,
  faCogs,
  faBullhorn,
  faQuoteLeft,
  faCalendar,
  faClock,
  faGraduationCap,
  faVideo,
} from '@fortawesome/free-solid-svg-icons'

import banner1 from '../../../images/pages/bpm/treinamento01.jpeg'
import banner2 from '../../../images/pages/bpm/treinamento02.jpeg'
import bannerTraining from '../../../images/pages/bpm/banner2.jpg'

const slides = [
  {
    title: <>FORMAÇÃO DE ANALISTAS DE PROCESSOS BPM</>,
    description: (
      <>
        Formação focada em projeto prático e aplicável em situações de trabalho
        reais
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>FORMAÇÃO EM GESTÃO POR PROCESSOS BPM</>,
    description: (
      <>
        Conceitos teóricos sobre a disciplina BPM com base em boas práticas e
        frameworks de processos
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const TreinamentoBPM: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faBullhorn,
      title: `Objetivo da formação`,
      description: (
        <>
          Capacitar o profissional para aplicar os conhecimentos de BPM na
          empresa na qual trabalha. Transferir conhecimentos, através do
          desenvolvimento de habilidades técnicas e buscando que o aluno tenha
          uma nova percepção da realidade, podendo contribuir positivamente para
          a gestão de resultados, seja na empresa em que atua ou mesmo em seu
          próprio negócio.
        </>
      ),
    },
    {
      faIcon: faCogs,
      title: `Público-alvo`,
      description: (
        <>
          Recomendado para profissionais que já atuam com gestão por processos
          BPM ou ainda que estão tendo o primeiro contato com a disciplina e
          buscam uma formação específica ou implantação e melhoria de processos.
          O público-alvo abrange Assistentes e Analistas de Processos,
          profissionais da Tecnologia da Informação, Planejamento e Controle da
          Produção, Administrativo e Financeiro, Controladoria, Gestores,
          Empresários e interessados no tema.
        </>
      ),
    },
    {
      faIcon: faUser,
      title: `Facilitador`,
      description: (
        <>
          <a
            href="https://www.linkedin.com/in/adiel-pavine-de-lima-b556b754/"
            target="_blank"
          >
            Adiel Pavine de Lima{' '}
          </a>
          <b>
            Sócio Diretor da Valore Brasil e Delegado do interior de SP pela
            ABPMP Brasil.
          </b>
          <br />
          Possui MBA em Gestão Tributária pela USP/RP. Certificações em ITIL V3,
          OCEB pela OMG e CBPP em Gestão por Processos pela ABPMP Brasil.
          Superior em Controladoria e Finanças. É especialista em administração
          financeria, composição e análise de resultados.
        </>
      ),
    },
  ]

  const classModules = [
    {
      count: 1,
      title: 'Arquitetura de Processos (Cadeia de Valor)',
      description: (
        <>
          <ul>
            <li>Processos ponta a ponta;</li>
            <li>Dono de processo;</li>
            <li>Foco do cliente;</li>
            <li>Indicadores de processo;</li>
            <li>Alinhamento estratégico (estratégia global);</li>
            <li>Arquitetura de processos. </li>
          </ul>
        </>
      ),
    },
    {
      count: 2,
      title: 'Modelagem de Processos',
      description: (
        <>
          <ul>
            <li>Como representar o valor entregue aos clientes;</li>
            <li>Objetivo da modelagem;</li>
            <li>Qualidade da modelagem;</li>
            <li>BPMN 2.0 - Notação para modelagem de processos de negócio</li>
            <li>Custos do processo;</li>
            <li>Modelagem AS IS dos processos;</li>
            <li>Procedimentos. </li>
          </ul>
        </>
      ),
    },
    {
      count: 3,
      title: 'Análise e melhoria de processos',
      description: (
        <>
          <ul>
            <li>Variáveis de um processo;</li>
            <li>Automação de processos (BPMS);</li>
            <li>Simulações;</li>
            <li>Controle de melhorias;</li>
            <li>Análise do processo;</li>
            <li>Modelagem da nova versão do processo TO BE.</li>
          </ul>
        </>
      ),
    },
    {
      count: 4,
      title: 'Desenho de processos',
      description: (
        <>
          <ul>
            <li>Implantação do escritório de processos;</li>
            <li>Portfólio de processos;</li>
            <li>Desenho de novos processos;</li>
            <li>Mudança de processos;</li>
            <li>Melhoria de processos;</li>
            <li>Desenho TO BE de processos;</li>
            <li>Publicação de processos.</li>
          </ul>
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="BPM - Treinamento BPM - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Treinamento BPM"
              className="solutionsTitle"
              description="Propor soluções através da capacitação de alunos na disciplina BPM de
              Gestão por Processos de Negócios para aplicação de técnicas de
              desenho, identificação de melhorias e implantação de mudanças em
              processos"
            />
          </div>

          <div
            className={clsx([
              `container pb-2 pt-2 pb-md-5 pt-md-2`,
              styles.containerLarge,
            ])}
          >
            <div className={styles.aboutTraining}>
              <div className="row pt-md-2 pb-md-5">
                <div className="col-12">
                  {/* <div className={styles.trainingDates}>
                    <div className={styles.image}>
                      <img src={bannerTraining} alt="treinamento" />
                    </div>
                    <div className={styles.items}>
                      <div className={styles.item}>
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>Próximas datas:</p>
                        <h3>
                          02, 16 e 30 de Abril
                          <br />e 14 de Maio
                        </h3>
                        <small>Sábados alternados</small>
                      </div>
                      <div className={styles.item}>
                        <FontAwesomeIcon icon={faClock} />
                        <p>Horários:</p>
                        <h3>
                          Início: 8:30 <br /> Término: 12h
                        </h3>
                      </div>
                      <div className={styles.item}>
                        <FontAwesomeIcon icon={faVideo} />
                        <p>Modalidade:</p>
                        <h3>100% online e ao vivo</h3>
                        <small>via Google Meet</small>
                      </div>
                      <div className={styles.item}>
                        <FontAwesomeIcon icon={faGraduationCap} />
                        <p>Carga horária:</p>
                        <h3>14 horas</h3>
                        <small>com certificado</small>
                      </div>
                      <div className={styles.links}>
                        <div className={styles.item}>
                          <p>Valor:</p>
                          <h3>R$ 990,00</h3>
                        </div>
                        <div className={styles.cta}>
                          <a
                            href="https://www.sympla.com.br/treinamento-para-formacao-de-analista-de-processos-bpm---valore-brasil-2022__1485422"
                            target="_blank"
                          >
                            Comprar ingressos
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className={styles.cardAbout}>
                    <div className={styles.header}>
                      <img
                        src={banner2}
                        alt={`sobre a formacao`}
                        title={`sobre a formacao`}
                      />
                    </div>
                    <div className={styles.content}>
                      <h3>Sobre a formação</h3>
                      <p>
                        BPM (Business Process Management) é disciplina gerencial
                        baseada no CBOK 4.0 que integra estratégia e objetivos
                        de uma organização com expectativas e necessidades do
                        cliente, por meio do foco em processos ponta a ponta. É
                        um modelo organizacional que serve para identificar,
                        desenhar, executar, monitorar e melhorar continuamente
                        processos de negócios.
                      </p>
                      <br />
                      <p>
                        <strong>Treinamento com certificado.</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-8 pl-md-5">
                  {featuresItems.map((item, key) => (
                    <div
                      className={clsx(styles.cardAbout, styles.cardIcon)}
                      key={key}
                    >
                      <div className={styles.content}>
                        <h3>
                          <FontAwesomeIcon icon={item.faIcon} />
                          {item.title}
                        </h3>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={styles.trainingContent}>
                    <div className={styles.titleContent}>
                      <div>
                        <h2>
                          <FontAwesomeIcon icon={faBookmark} />
                          Conteúdo da formação
                        </h2>
                        <p>
                          Conheça agora os 04 principais módulos que contemplam
                          a Formação para Analistas de Processos BPM.
                        </p>
                      </div>
                    </div>
                    <div className={styles.trainingGrid}>
                      {classModules.map((module, key) => (
                        <div className={styles.item} key={key}>
                          <div className={styles.trainingCard}>
                            <div className={styles.header}>
                              <h3>Módulo 0{module.count}</h3>
                              <h4>{module.title}</h4>
                            </div>
                            <div className={styles.content}>
                              {module.description}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className={styles.testimonialsContent}>
                    <div className="row align-items-center">
                      <div className="col-12 col-md-4">
                        <div className={styles.titleContent}>
                          <div>
                            <h2>
                              <FontAwesomeIcon icon={faQuoteLeft} /> Depoimentos
                            </h2>
                            <p>
                              Saiba o que nossos ex-alunos falam sobre a
                              Formação em Analista de Processos e o que de fato
                              mudou em sua trajetória!
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-8">
                        <Testimonials />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <Divider />
        </div>
      </section>
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default TreinamentoBPM
